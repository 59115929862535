var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"7"}},[_c('b-card',{attrs:{"title":"Info Forum"}},[_c('div',{staticClass:"border-bottom pb-1 mb-1"},[_c('h5',[_vm._v("Judul")]),_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.formDetail.title))])]),_c('div',{staticClass:"border-bottom pb-1 mb-1"},[_c('h5',[_vm._v("Lampiran")]),_c('img',{staticClass:"w-100 rounded",attrs:{"src":_vm.formDetail.attachment,"alt":""}})]),_c('div',{staticClass:"border-bottom pb-1 mb-1"},[_c('h5',[_vm._v("Deskripsi")]),_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.formDetail.description))])]),_c('div',{staticClass:"border-bottom pb-1 mb-1"},[_c('h5',[_vm._v("Dibuat Pada")]),_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.formDetail.created_at))])])]),_c('b-card',{attrs:{"title":"Info Kategori"}},[_c('div',{staticClass:"text-center"},[_c('img',{staticStyle:{"width":"150px","height":"150px","object-fit":"cover"},attrs:{"src":_vm.formDetail.category
              ? _vm.formDetail.category.image
              : require("@/assets/images/avatars/no-image.png"),"alt":""}})]),_c('div',{staticClass:"border-bottom pb-1 mb-1"},[_c('h5',[_vm._v("Jenis Kategori")]),_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.formDetail.category ? _vm.formDetail.category.name : "-")+" ")])])]),_c('b-card',{attrs:{"title":"Info User"}},[_c('div',{staticClass:"text-center"},[_c('img',{staticClass:"rounded-pill",staticStyle:{"width":"150px","height":"150px","object-fit":"cover"},attrs:{"src":_vm.formDetail.user
              ? _vm.formDetail.user.avatar
              : require("@/assets/images/avatars/null-profile.webp"),"alt":""}})]),_c('div',{staticClass:"border-bottom pb-1 mb-1"},[_c('h5',[_vm._v("Nama")]),_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.formDetail.user ? _vm.formDetail.user.name : "-"))])]),_c('div',{staticClass:"border-bottom pb-1 mb-1"},[_c('h5',[_vm._v("Profesi")]),_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.formDetail.user ? _vm.formDetail.user.biodata.profession : "-")+" ")])]),_c('div',{staticClass:"border-bottom pb-1 mb-1"},[_c('h5',[_vm._v("Tier")]),_c('p',{staticClass:"mb-0 text-capitalize"},[_vm._v(" "+_vm._s(_vm.formDetail.user ? _vm.formDetail.user.biodata.tier : "-")+" ")])])])],1),_c('b-col',{staticClass:"position-relative",staticStyle:{"z-index":"0"},attrs:{"cols":"12","md":"5"}},[_c('b-card',{staticClass:"sticky-top",attrs:{"title":"Comment"}},[(_vm.chatList.length === 0)?_c('div',{staticClass:"text-center"},[_vm._v(" Tidak ada Comment yang tersedia ")]):_vm._l((_vm.chatList),function(chat){return _c('div',{key:chat.uuid,staticClass:"mb-2 d-flex",staticStyle:{"gap":"10px"}},[_c('img',{staticClass:"profile-image",attrs:{"src":chat.user.avatar
                ? chat.user.avatar
                : require("@/assets/images/avatars/null-profile.webp"),"alt":""}}),_c('div',{staticClass:"d-flex flex-column",staticStyle:{"gap":"4px"}},[_c('p',{staticClass:"profile-name mb-0"},[_vm._v(_vm._s(chat.user.name))]),(chat.attachment !== null)?_c('div',{staticClass:"d-flex flex-column"},[_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(chat.text))]),_c('img',{staticClass:"w-100 rounded",attrs:{"src":chat.attachment,"alt":""}})]):_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(chat.text)+" ")]),_c('small',{staticClass:"text-secondary"},[_vm._v(_vm._s(chat.created_at))]),(chat.replies_count > 0)?_c('div',{staticClass:"profile-reply",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.seeReplyComment(chat.uuid)}}},[_vm._v(" "+_vm._s(_vm.isLoading && _vm.showReply === chat.uuid ? "Tunggu.." : "Lihat Balasan (" + chat.replies_count + ")")+" ")]):_vm._e(),(chat.replies_count > 0 && _vm.showReply === chat.uuid)?_vm._l((_vm.chatReplyList),function(chatReply){return _c('div',{key:chatReply.uuid,staticClass:"d-flex mt-1",staticStyle:{"gap":"10px"}},[_c('img',{staticClass:"profile-image",attrs:{"src":chatReply.user.avatar
                      ? chatReply.user.avatar
                      : require("@/assets/images/avatars/null-profile.webp"),"alt":""}}),_c('div',{staticClass:"d-flex flex-column",staticStyle:{"gap":"4px"}},[_c('p',{staticClass:"profile-name mb-0"},[_vm._v(_vm._s(chatReply.user.name))]),(chatReply.attachment !== null)?_c('div',{staticClass:"d-flex flex-column"},[_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(chatReply.text))]),_c('img',{staticClass:"w-100 rounded",attrs:{"src":chatReply.attachment,"alt":""}})]):_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(chatReply.text)+" ")]),_c('small',{staticClass:"text-secondary"},[_vm._v(_vm._s(chatReply.created_at))])])])}):_vm._e()],2)])})],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }