<template>
  <b-row>
    <b-col cols="12" md="7">
      <b-card title="Info Forum">
        <div class="border-bottom pb-1 mb-1">
          <h5>Judul</h5>
          <p class="mb-0">{{ formDetail.title }}</p>
        </div>
        <div class="border-bottom pb-1 mb-1">
          <h5>Lampiran</h5>
          <img :src="formDetail.attachment" class="w-100 rounded" alt="" />
        </div>
        <div class="border-bottom pb-1 mb-1">
          <h5>Deskripsi</h5>
          <p class="mb-0">{{ formDetail.description }}</p>
        </div>
        <div class="border-bottom pb-1 mb-1">
          <h5>Dibuat Pada</h5>
          <p class="mb-0">{{ formDetail.created_at }}</p>
        </div>
      </b-card>
      <b-card title="Info Kategori">
        <div class="text-center">
          <img
            :src="
              formDetail.category
                ? formDetail.category.image
                : require(`@/assets/images/avatars/no-image.png`)
            "
            style="width: 150px; height: 150px; object-fit: cover"
            alt=""
          />
        </div>
        <div class="border-bottom pb-1 mb-1">
          <h5>Jenis Kategori</h5>
          <p class="mb-0">
            {{ formDetail.category ? formDetail.category.name : "-" }}
          </p>
        </div>
        <!-- <div class="border-bottom pb-1 mb-1">
          <h5>Gambar</h5>
          <img
            :src="formDetail.category.image"
            style="width: 40px; height: 40px; object-fit: cover"
            alt=""
          />
        </div> -->
      </b-card>
      <b-card title="Info User">
        <div class="text-center">
          <img
            :src="
              formDetail.user
                ? formDetail.user.avatar
                : require(`@/assets/images/avatars/null-profile.webp`)
            "
            class="rounded-pill"
            style="width: 150px; height: 150px; object-fit: cover"
            alt=""
          />
        </div>
        <div class="border-bottom pb-1 mb-1">
          <h5>Nama</h5>
          <p class="mb-0">{{ formDetail.user ? formDetail.user.name : "-" }}</p>
        </div>
        <div class="border-bottom pb-1 mb-1">
          <h5>Profesi</h5>
          <p class="mb-0">
            {{ formDetail.user ? formDetail.user.biodata.profession : "-" }}
          </p>
        </div>
        <div class="border-bottom pb-1 mb-1">
          <h5>Tier</h5>
          <p class="mb-0 text-capitalize">
            {{ formDetail.user ? formDetail.user.biodata.tier : "-" }}
          </p>
        </div>
      </b-card>
    </b-col>
    <b-col cols="12" md="5" class="position-relative" style="z-index: 0">
      <b-card title="Comment" class="sticky-top">
        <div class="text-center" v-if="chatList.length === 0">
          Tidak ada Comment yang tersedia
        </div>
        <template v-else>
          <div
            class="mb-2 d-flex"
            style="gap: 10px"
            v-for="chat in chatList"
            :key="chat.uuid"
          >
            <img
              :src="
                chat.user.avatar
                  ? chat.user.avatar
                  : require(`@/assets/images/avatars/null-profile.webp`)
              "
              class="profile-image"
              alt=""
            />
            <div class="d-flex flex-column" style="gap: 4px">
              <p class="profile-name mb-0">{{ chat.user.name }}</p>
              <div v-if="chat.attachment !== null" class="d-flex flex-column">
                <p class="mb-0">{{ chat.text }}</p>
                <img :src="chat.attachment" class="w-100 rounded" alt="" />
              </div>
              <p class="mb-0" v-else>
                {{ chat.text }}
              </p>
              <small class="text-secondary" >{{ chat.created_at }}</small>
              <div
                v-if="chat.replies_count > 0"
                class="profile-reply"
                style="cursor: pointer"
                @click="seeReplyComment(chat.uuid)"
              >
                {{
                  isLoading && showReply === chat.uuid
                    ? "Tunggu.."
                    : "Lihat Balasan (" + chat.replies_count + ")"
                }}
              </div>
              <template
                v-if="chat.replies_count > 0 && showReply === chat.uuid"
              >
                <div
                  class="d-flex mt-1"
                  v-for="chatReply in chatReplyList"
                  :key="chatReply.uuid"
                  style="gap: 10px"
                >
                  <img
                    :src="
                      chatReply.user.avatar
                        ? chatReply.user.avatar
                        : require(`@/assets/images/avatars/null-profile.webp`)
                    "
                    class="profile-image"
                    alt=""
                  />
                  <div class="d-flex flex-column" style="gap: 4px">
                    <p class="profile-name mb-0">{{ chatReply.user.name }}</p>
                    <div
                      v-if="chatReply.attachment !== null"
                      class="d-flex flex-column"
                    >
                      <p class="mb-0">{{ chatReply.text }}</p>
                      <img
                        :src="chatReply.attachment"
                        class="w-100 rounded"
                        alt=""
                      />
                    </div>
                    <p class="mb-0" v-else>
                      {{ chatReply.text }}
                    </p>
                    <small class="text-secondary" >{{ chatReply.created_at }}</small>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </template>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BCard,
  BCardText,
  BForm,
  BButton,
  BCol,
  BRow,
  BSpinner,
} from "bootstrap-vue";
export default {
  components: {
    BCard,
    BCardText,
    BForm,
    BButton,
    BCol,
    BRow,
    BSpinner,
  },

  data() {
    return {
      isLoading: false,
      formDetail: {},
      chatList: [],
      chatReplyList: [],
      showReply: null,
    };
  },

  created() {
    this.loadForum();
  },

  mounted() {
    this.loadComment();
  },
  methods: {
    loadForum() {
      this.isLoading = true;
      const params = this.$route.params.uuid;
      this.$http.get("/api/v1/admin/forums/" + params).then((response) => {
        this.formDetail = response.data.data;
        this.isLoading = false;
      });
    },
    loadComment() {
      this.isLoading = true;
      const params = this.$route.params.uuid;
      this.$http
        .get(`/api/v1/admin/forums/${params}/comment`)
        .then((response) => {
          this.chatList = response.data.data.items;
          this.isLoading = false;
        });
    },
    seeReplyComment(id) {
      this.chatReplyList = [];
      this.isLoading = true;
      this.showReply = id;
      this.$http
        .get(`/api/v1/admin/forums/${id}/reply-comment`)
        .then((response) => {
          this.chatReplyList = response.data.data.items;
          this.isLoading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.profile-image {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  object-fit: cover;
}

.profile-name {
  font-weight: 700;
}

.profile-reply {
  font-weight: 700;
  font-size: 12px;
}
</style>
